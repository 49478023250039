import { SignalService } from '@awork/_shared/services/signal-service/signal.service'
import { differenceInSeconds } from '@awork/_shared/functions/date-fns-wrappers'
import { Component, Injector } from '@angular/core'
import { Subscription } from 'rxjs'
import isTest from '@awork/_shared/functions/is-test'

function isFunction(fn): boolean {
  return typeof fn === 'function'
}

// Function used to set an Injector from AppModule to inject the SignalService
let injector: Injector
export function setInjector(i: Injector): void {
  injector = i
}

/**
 * Re-inits the component in case of WS reconnection to re-fetch all data
 * @param event - Event hook that should be re-called
 * @param unsubscribeEvent - Event hook used to unsubscribe from the Signal Service's subject
 * @constructor
 */
export function ReInit({ event = 'ngOnInit', unsubscribeEvent = 'ngOnDestroy' } = {}) {
  let original: Function
  let initOn: Date
  let component: Component
  let signalSubscription: Subscription

  // Exclude the entire decorator in test environment
  if (isTest()) {
    return void 0
  }

  return function (constructor: Function) {
    original = constructor.prototype[event]
    const originalUnsubscribe = constructor.prototype[unsubscribeEvent]

    if (!isFunction(original)) {
      console.warn(`${constructor.name} is using @ReInit but does not implement ${event}`)
    }

    if (!isFunction(originalUnsubscribe)) {
      console.warn(`${constructor.name} is using @ReInit but does not implement ${unsubscribeEvent}`)
    }

    // Init event
    constructor.prototype[event] = function () {
      const signalService: SignalService = this.hasOwnProperty('signalService')
        ? (this['signalService'] as SignalService)
        : injector.get(SignalService)

      component = this
      initOn = new Date()

      if (signalService instanceof SignalService) {
        // If the Signal Service just connected and the component was initialized more than 1 minute ago
        // Re-init it (call the component's event hook)
        signalSubscription = signalService.connected.subscribe(() => {
          if (differenceInSeconds(new Date(), initOn) > 60) {
            initOn = new Date()
            callOriginal()
          }
        })
      } else {
        console.warn(`${constructor.name} is using @ReInit but does not inject SignalService`)
      }

      callOriginal()
    }

    // Unsubscribe event
    constructor.prototype[unsubscribeEvent] = function () {
      // Unsubscribe to the Signal Service's subject when the component is destroyed
      if (signalSubscription && !signalSubscription.closed) {
        signalSubscription.unsubscribe()
      }

      if (isFunction(originalUnsubscribe)) {
        originalUnsubscribe.apply(this, arguments)
      }
    }
  }

  /**
   * Calls the original component's event hook
   */
  function callOriginal(): void {
    if (isFunction(original)) {
      original.apply(component, arguments)
    }
  }
}
